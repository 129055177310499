import React, { useEffect, useState } from 'react';
import MessagesCards from "./MessagesCards"
import axios from "axios"
import { makeStyles } from '@material-ui/core/styles';
import grey from "@material-ui/core/colors/grey"
import { Button, Container, Divider, Grid, TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Pagination from "../Pagination"
import ProgrssBar from '../ProgressBar'
import MuiPhoneNumber from "material-ui-phone-number";
import { DateRangePicker } from "materialui-daterange-picker";
import DirectionIcon from '@material-ui/icons/RepeatTwoTone';



const useStyles = makeStyles((theme) => ({
    root: {
        width: "100vw",
        height: "100vh",
        backgroundColor: theme.palette.grey[300],
        paddingTop: theme.spacing(5)

    },
}))

const Messages = () => {
    document.body.style.backgroundColor = grey[300];
    const [loaded, setloaded] = useState(false)
    const [data, setData] = useState({
        info: {},
        next: {},
        results: []

    })
    
    const placeHolderDate = new Date()
    const todayUTCDateYMD = `${placeHolderDate.getUTCFullYear()}-${parseInt( placeHolderDate.getUTCMonth() + 1) > 9 ? parseInt(placeHolderDate.getUTCMonth() + 1) : "0" + parseInt(placeHolderDate.getUTCMonth() + 1)}-${placeHolderDate.getUTCDate() > 9 ? placeHolderDate.getUTCDate() : "0" + placeHolderDate.getUTCDate()}`
    const todayUTCDateMDY = `${placeHolderDate.getUTCMonth() + 1}/${placeHolderDate.getUTCDate()}/${placeHolderDate.getUTCFullYear()}`
    const [progress, setProgress] = useState(false)

    const [filter, setFilter] = useState({date:{"$gte":todayUTCDateYMD+"T00:00:00.000Z","$lte":todayUTCDateYMD+"T23:59:59.000Z"}})

    const [SimBoxesUnifiedSet, setSimBoxesUnifiedSet] = useState([])

    const [junk, setJunk] = useState("")

    const [page, setPage] = useState({ pageNumber: "1", limit: "24" })


    const [open, setOpen] = useState(false);

    const toggle = () => setOpen(!open);
    const [dateText, setDateText] = useState(`${todayUTCDateMDY} - ${todayUTCDateMDY}`)

    const [campaignList, setCampaignList] = useState([])

    const calFunc = (range) => {
        toggle()
        const { startDate, endDate } = range


        const getUTCFormat = (longDate) => {
            // console.log(log)
            const year = new Date(longDate).getFullYear()
            const month = parseInt(new Date(longDate).getMonth()) + 1
            // console.log(month)
            const monthFinal = (month > 9) ? month : "0" + month
            const date = (new Date(longDate).getDate() > 9) ? new Date(longDate).getDate() : "0" + new Date(longDate).getDate()

            return `${year}-${monthFinal}-${date}`
        }

        const startDateISO = getUTCFormat(startDate) + "T00:00:00.000Z"
        const endDateISO = getUTCFormat(endDate) + "T23:59:59.000Z"
        // const startDateLocal = new Date(startDate).toLocaleDateString()

        // console.log(startDateISO, endDateISO)

        let date = { $gte: startDateISO, $lte: endDateISO }
        //  date = { date }
        //    const dataString = data.toString()
        //    console.log(data)
        setFilter({ ...filter, date })

        const getlocalDateFormat = (date) => {
            return new Date(date).toLocaleDateString()
        }

        setDateText(getlocalDateFormat(range.startDate) + " - " + getlocalDateFormat(range.endDate))
        // console.log(range)
    }

    const clearCalc = () => {
        setDateText("")
        filter.date = undefined
        setFilter({ ...filter })
        toggle()
    }


    const check = () => {
        if (filter.type === "MT") {
            setFilter({ ...filter, type: "MO" })
            setPage({ ...page, pageNumber: "" })
        } else if (filter.type === "MO") {

            setJunk("junks-mt")
            filter.type = undefined
            setFilter({ ...filter, text: { $regex: "Free Msg: Unable to send message" } })
            setPage({ ...page, pageNumber: "" })
        } else if (junk === "junks-mt") {
            setJunk("junks-mo")
            filter.type = undefined
            filter.text = undefined
            setFilter({ ...filter })
            setPage({ ...page, pageNumber: "" })

        } else if (junk === "junks-mo") {
            setJunk("")
            filter.type = undefined
            filter.text = undefined
            setFilter({ ...filter })
            setPage({ ...page, pageNumber: "" })
        } else {
            setFilter({ ...filter, type: "MT" })
            setPage({ ...page, pageNumber: "" })
        }
    }



    function handlePage(val) {
        setPage({ ...page, pageNumber: val })
    }

    function handleLimit(val) {
        if (page.limit !== val) {
            setPage({ ...page, limit: val })
        }
    }


    const phoneFilter = (val) => {
        val = val.replace(" ", "")
        val = val.replace("+1", "1").replace(" ", "").replace("-", "").replace("(", "").replace(")", "")
        // console.log(val)
        if (val !== "1") {
            setPage({ ...page, pageNumber: "" })
            setFilter({ ...filter, phone: val })
            // console.log(filter)
        } else {
            filter.phone = undefined
            setFilter({ ...filter })
            // console.log(filter)
        }

    }

    const simboxFilter = (event) => {
        const { value } = event.target
        setPage({ ...page, pageNumber: "" })
        if (value) {

            setFilter({ ...filter, $or: [{ "smsSendStatus.simBoxPort": value }, { "smsReceiveStatus.simBoxPort": value }] })
        } else {
            filter.$or = undefined
            setFilter({ ...filter })
        }
        // console.log(value)
    }

    const simPortFilter = (event) => {
        const { value } = event.target
        setPage({ ...page, pageNumber: "" })
        if (value) { setFilter({ ...filter, port: value }) }
        else { filter.port = undefined; setFilter({ ...filter }) }
    }

    const guidFilter = (event) => {
        setPage({ ...page, pageNumber: "" })
        const { value } = event.target
        if (value) { setFilter({ ...filter, _id: value }) }
        else { filter._id = undefined; setFilter({ ...filter }) }
    }


    const status = (event) => {
        setPage({ ...page, pageNumber: "" })



        const { value } = event.target

        if (value === "delivered") {
            filter["smsSendStatus.status"] = undefined
            filter["smsSendStatus"] = undefined
            filter["smsReceiveStatus"] = undefined
            setFilter({ ...filter, "smsSendStatus.dlrStatus": value })
        }
        else if (value === "failed") {
            filter["smsSendStatus.status"] = undefined
            filter["smsSendStatus"] = undefined
            filter["smsReceiveStatus"] = undefined
            setFilter({ ...filter, "smsSendStatus.dlrStatus": value })
        }
        else if (value === "Success") {
            filter["smsSendStatus.dlrStatus"] = undefined
            filter["smsSendStatus"] = undefined
            filter["smsReceiveStatus"] = undefined
            setFilter({ ...filter, "smsSendStatus.status": value, "smsSendStatus.dlrStatus": null })
        }
        else if (value === "Reject") {
            filter["smsSendStatus.dlrStatus"] = undefined
            filter["smsSendStatus"] = undefined
            filter["smsReceiveStatus"] = undefined
            setFilter({ ...filter, "smsSendStatus.status": value })
        }
        else if (value === "internal error") {
            filter["smsSendStatus.status"] = undefined
            filter["smsSendStatus.dlrStatus"] = undefined
            setFilter({ ...filter, "smsSendStatus": null, "smsReceiveStatus": null })
        } else {
            filter["smsSendStatus.status"] = undefined
            filter["smsSendStatus"] = undefined
            filter["smsReceiveStatus"] = undefined
            filter["smsSendStatus.dlrStatus"] = undefined
            setFilter({ ...filter })
        }
    }

    // const filter = {
    //     // "_id" : "608502493a0c4d391d753a3d",
    //     type : "MO"
    // }

    const carrierFilter = (event) => {
        setPage({ ...page, pageNumber: "" })
        const { value } = event.target
        if (value) {
            if (value === "") {
                filter.carrier = undefined
                setFilter({ ...filter })
            } else {
                setFilter({ ...filter, carrier: value })
            }
        } else {
            filter.carrier = undefined
            setFilter({ ...filter })
        }
    }

    const filterAgentMT = (event) => {
        setPage({ ...page, pageNumber: "" })
        const { value } = event.target
        if (value) {
            if (value === "") {
                filter.campaignID = undefined
                setFilter({ ...filter })
            } else {
                if (value === "hide") {
                    setFilter({ ...filter, campaignID: { $ne: "AGENT-MT" } })
                }
                if (value === "show") {
                    setFilter({ ...filter, campaignID: "AGENT-MT" })
                }
            }
        } else {
            filter.campaignID = undefined
            setFilter({ ...filter })
        }
    }

    const campaignFilter = (event) => {
        setPage({ ...page, pageNumber: "" })
        const { value } = event.target
        if (value) {
            if (value === "") {
                filter.campaign = undefined
                setFilter({ ...filter })
            } else {
                setFilter({ ...filter, campaign: value })
            }
        } else {
            filter.campaign = undefined
            setFilter({ ...filter })
        }
    }
    



    const history = useHistory()

    useEffect(() => {

        document.title = "GMP - Please Wait..."
        let source = axios.CancelToken.source()
        const getCampaignsMenu = async () => {

            const headers = {
                withCredentials: true
            }
            try {
                const campaignListMenu = await axios.get(process.env.REACT_APP_API_URL + "/campaign",  headers )
                setCampaignList(campaignListMenu.data.results.map((val) => val.campaign))                
            } catch (error) {
                if (axios.isCancel(error)) {
                    console.info("Last Request Was Cancelled")
                } else {
                    if (error.response.status === 401) {
                        history.push("/")
                    }
                    // console.log(error)
                    setProgress(false)
                }
            }
        }
        getCampaignsMenu()

        return () => {
            source.cancel()
        }

    }, [history, filter, page])



    useEffect(() => {

        document.title = "GMP - Please Wait..."
        let source = axios.CancelToken.source()
        const getData = async () => {

            // const headers = {
            //     withCredentials: true
            // }
            try {
                // const user = localStorage.getItem("user");
                setProgress(true)

                const getDataResult = await axios.post(process.env.REACT_APP_API_URL+ `/reports/${junk.split("-")[0]}?page=${page.pageNumber}&limit=${page.limit}&sort=date&order=desc`, filter, { cancelToken: source.token, withCredentials: true })
                // console.log(getDataResult)

                setData({
                    info: getDataResult.data.info,
                    next: getDataResult.data.next,
                    results: getDataResult.data.results

                })
                setProgress(false)
                setloaded(true)
                document.title = "GMP - Messages"
                // setloaded(true)
                // console.log(getDataResult)

            } catch (error) {
                if (axios.isCancel(error)) {
                    console.info("Last Request Was Cancelled")
                } else {
                    if (error.response.status === 401) {
                        history.push("/")
                    }
                    // console.log(error)
                    setProgress(false)
                    setData({
                        info: { "counts": 0 }
                    })
                }
            }
        }
        getData()



        // setInterval(() => {
        //     getData()
        // }, 30000);

        return () => {
            // console.log("unmount")
            source.cancel()

            // console.clear()
        }

    }, [history, filter, page, junk])



    useEffect(() => {

        if(!loaded) return 
        let source = axios.CancelToken.source()
        const boxes = async () => {
            // const headers = {
            //     withCredentials: true
            // }


            try {

                const getSimBoxesUnifiedSetList = await axios.get(process.env.REACT_APP_API_URL + "/gateway/simboxes-unified", { cancelToken: source.token, withCredentials: true })
                setSimBoxesUnifiedSet(getSimBoxesUnifiedSetList.data.SimBoxesUnifiedSet)

            } catch (error) {
                if (axios.isCancel(error)) {
                    console.info("Last Request Was Cancelled")
                } else {
                    if (error.response.status === 401) {
                        history.push("/")
                    }
                }
            }
        }

        boxes()

        return () => {
            source.cancel()
        }

    }, [history,loaded])


    const classes = useStyles();
    if (loaded) {

        return (
            <React.Fragment>
                { progress && <ProgrssBar />}
                <br />

                <Grid container justify="center" spacing={1}>

                    <Grid item style={{ display: "flex" }}>

                        <Button color="primary" onClick={() => check()} variant="contained" startIcon={<DirectionIcon />}>Direction {filter.type || junk || "MT / MO"}

                        </Button>
                    </Grid>

                    <Grid item>
                        <TextField onClick={toggle}
                            variant="outlined"
                            label="Calendar"
                            placeholder="Date Range"
                            style={{ width: "190px" }}
                            onChange={clearCalc} value={dateText} />
                    </Grid>


                    <Grid item>
                        &nbsp;  <MuiPhoneNumber
                            variant="outlined"
                            name="phone"
                            id="phone"
                            label="Phone Number"
                            data-cy="user-phone"
                            defaultCountry={"us"}
                            countryCodeEditable={false}
                            value={filter.phone}
                            onChange={phoneFilter}
                        />
                    </Grid>

                    <Grid item>
                        &nbsp;  <TextField
                            select
                            variant="outlined"
                            label="SimBox Port"
                            placeholder="MT/MO Machine Port"
                            style={{ width: "140px" }}
                            SelectProps={{
                                native: true,
                            }}
                            onChange={simboxFilter}>

                            <option value="">

                            </option>

                            {
                                SimBoxesUnifiedSet.map((val) => {
                                    return <option key={val} value={val}>{val}</option>
                                })
                            }

                            {/* <option value="8012">
                                8012
                </option>
                            <option value="8013">
                                8013
                </option>
                            <option value="8014">
                                8014
                </option>
                            <option value="8015">
                                8015
                </option>
                            <option value="8016">
                                8016
                </option> */}
                        </TextField>
                    </Grid>

                    <Grid item>
                        &nbsp;  <TextField
                            variant="outlined"
                            label="Sim Port"
                            placeholder="Sim Card"
                            style={{ width: "100px" }}
                            onChange={simPortFilter}
                        />
                    </Grid>

                    <Grid item>
                        &nbsp;  <TextField
                            style={{ width: "245px" }}
                            variant="outlined"
                            label="Transaction GUID / ObjectID"
                            placeholder="60a556285a0ea90015d6141f"

                            onChange={guidFilter}
                        />
                    </Grid>

                    <Grid item>
                        &nbsp;  <TextField
                            select
                            variant="outlined"
                            label="Message Status"
                            placeholder="MT/MO Status"
                            style={{ width: "180px" }}
                            SelectProps={{
                                native: true,
                            }}
                            onChange={status}>

                            <option value="">

                            </option>

                            <option value="delivered">
                                Delivered
                </option>
                            <option value="failed">
                                Failed
                </option>
                            <option value="Success">
                                Success
                </option>
                            <option value="Reject">
                                Reject
                </option>
                            <option value="internal error">
                                Internal Error
                </option>
                        </TextField>
                    </Grid>

                <Divider />

                <Grid item>
                        &nbsp;  <TextField
                            select
                            variant="outlined"
                            label="Carrier"
                            placeholder="Carrier Filter"
                            style={{ width: "180px" }}
                            SelectProps={{
                                native: true,
                            }}
                            onChange={carrierFilter}>

                            <option value="">

                            </option>

                            <option value="VZW">
                                Verizon
                            </option>
                            <option value="ATT">
                                AT&T
                            </option>
                            <option value="TMO">
                                TMobile
                            </option>
                            <option value="SPRINT">
                                Sprint
                            </option>
                            <option value="ALLOTHER">
                            ALLOTHER
                            </option>
                        </TextField>
                    </Grid>

                    <Grid item>
                        &nbsp;  <TextField
                            select
                            variant="outlined"
                            label="Campaign"
                            placeholder="Filter Campaign"
                            // style={{ width: "220px" }}
                            SelectProps={{
                                native: true,
                            }}
                            value={filter.campaign}
                            onChange={campaignFilter}>

                            <option value="">

                            </option>

                            {
                                campaignList.map((val) => {
                                    return val==="NULL" ? <option key={val} value={val}>{`DEFAULT(${val})`}</option> : <option key={val} value={val}>{val}</option>
                                }
                                )
                            }


                        </TextField>
                    </Grid>

                    <Grid item>
                        &nbsp;  <TextField
                            select
                            variant="outlined"
                            label="Agent-MT"
                            placeholder="Filter Agent-MT"
                            style={{ width: "180px" }}
                            SelectProps={{
                                native: true,
                            }}
                            onChange={filterAgentMT}>

                            <option value="">

                            </option>

                            <option value="hide">
                                Hide
                            </option>
                            <option value="show">
                                Show
                            </option>


                        </TextField>
                    </Grid>
                    


                </Grid>

                <br />

                {open &&
                    <Grid item>
                        <br />
                        <br />
                        <br />


                        <DateRangePicker

                            open={open}
                            toggle={toggle}
                            onChange={(range) => calFunc(range)}

                        />

                    </Grid>
                }
                <Divider />




                {(data.info.counts > 0) && <Pagination showHead="true" pages={data.info.pages} totalCounts={data.info.counts} handlePage={handlePage} handleLimit={handleLimit} currentLimit={page.limit} currentPage={data.info.currentPageNumber} />}
                <Container className={classes.root}>
                    {(data.info.counts > 0) ?
                        <React.Fragment>
                            <Grid container spacing={2}>
                                {
                                    data.results.map((val) => {
                                        // console.log(val.smsSendStatus)
                                        return <MessagesCards
                                            key={val._id}
                                            dataId={val._id}
                                            dateTime={val.date}
                                            msgType={(val.type !== "MO-JUNK") ? val.type : "JNK"}
                                            carrier={val.carrier}
                                            phone={val.phone}
                                            text={val.text}
                                            simBoxPort={val.smsSendStatus ? val.smsSendStatus.simBoxPort : "Error" && val.smsReceiveStatus ? val.smsReceiveStatus.simBoxPort : "Error"}
                                            port={val.port}
                                            dlrStatus={val.smsSendStatus ? val.smsSendStatus.dlrStatus || val.smsSendStatus.status : val.smsReceiveStatus ? "Received" : "Internal Error"}
                                            campaign={val.campaign}
                                            campaignID={val.campaignID}
                                            leadID={val.leadID}
                                        />

                                    })

                                }
                            </Grid>
                            <Pagination pages={data.info.pages} showHead="false" totalCounts={data.info.counts} handlePage={handlePage} handleLimit={handleLimit} currentLimit={page.limit} currentPage={data.info.currentPageNumber} />
                            <br />
                            <br />
                            <br />
                            <br />
                        </React.Fragment>
                        :
                        <h1><center>No Records Found...</center></h1>
                    }
                </Container>
            </React.Fragment>
        );


    } else {


        return (
            <React.Fragment>
                <ProgrssBar />
                <h1>Please Wait...</h1>
            </React.Fragment>
        );
    }
}

export default Messages