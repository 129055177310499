import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home"; // Use Material UI Home icon

const Page404 = () => {

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>404</h1>
      <p style={styles.message}>Oops! The page you are looking for does not exist.</p>
      <Link to="/about" style={{ textDecoration: "none" }}>
        <Button
          startIcon={<HomeIcon />} // Icon like the login button
          color="primary"
          variant="contained"
          size="large"
        >
          Go to Main
        </Button>
      </Link>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start", // Moves it slightly up
    alignItems: "center",
    height: "100vh",
    textAlign: "center",
    backgroundColor: "#f8f9fa",
    color: "#333",
    paddingTop: "20vh", // Moves content higher
  },
  title: {
    fontSize: "6rem",
    fontWeight: "bold",
    margin: "0",
  },
  message: {
    fontSize: "1.5rem",
    marginBottom: "20px",
  },
};

export default Page404;
